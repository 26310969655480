.page-header-title
{
  font-size: 24px;
  margin-left: 20px;
}

.page-head-content-padding
{
  padding: 0 10px!important;
}

.page-header .breadcrumb-line-component
{
  margin-left: 10px;
  margin-right: 10px;
}

.generic-breadcrumb
{
  li a
  {
    font-size: 14px;

    i
    {
      font-size: 14px;
    }
  }
}

.breadcrumb > .active
{
  font-size: 14px;
}

.bootstrap-select > .btn
{
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  font-size: 14px;
}

.dropdown-menu > li > a
{
  font-size: 14px;
}

.generic-label
{
  font-size: 15px;
}

.genericInput
{
  font-size: 14px;
  background-color: transparent;
  border-right: none;
  border-left: none;
  border-top: none;
  border-radius: 0px;
  margin-bottom: 12px;
}

.final-det-slider
{
  border:none!important;
}

.daterangepicker .daterangepicker_input i
{
  top: unset!important;
  left: unset!important;
}

input[type=range]{
  -webkit-appearance: none;
  background: none;
}

input[type=range]::-webkit-slider-runnable-track {
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type=range]::-ms-track {
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type=range]::-moz-range-track {
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #458c47;
  margin-top: -10px;
  position: relative;
}

input[type=range]::-ms-thumb {
  -webkit-appearance: none;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #458c47;
  margin-top: -10px;
  position: relative;
}

input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #458c47;
  margin-top: -10px;
  position: relative;
}

input[type=range]:focus {
  outline: none;
  &::-webkit-slider-thumb:after {
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    //background: #eee;
    border-radius: 5px;
    color: #458c47;
    padding: 5px 10px;
    border: 2px solid #458c47;
  }
  &::-ms-thumb:after {
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    //background: #eee;
    border-radius: 5px;
    color: #458c47;
    padding: 5px 10px;
    border: 2px solid #458c47;
  }
  &::-moz-range-thumb:after {
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    //background: #eee;
    border-radius: 5px;
    color: #458c47;
    padding: 5px 10px;
    border: 2px solid #458c47;
  }
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

input[type=range]:focus::-ms-track {
  background: #ccc;
}

input[type=range]:focus::-moz-range-track {
  background: #ccc;
}

.modalButton
{
  font-size: 14px;
  background-color: #f4f4f4;
}

@media (max-width: 1032px) {
  .generic-label
  {
    font-size: 13px;
  }

  ul.steps-header > li > span, ul.steps-header > li > a > span {
    font-size: 12px !important;
  }

}

@media (max-width: 769px) {
  ul.steps-header > li > span, ul.steps-header > li > a > span {
    font-size: 10px !important;
  }

}